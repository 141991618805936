export default {

  //ANGLAIS 
  en: {
    Dashboard: 'Dashboard',
    Utilisateurs: 'Users',
    Compagnies: 'Companies',
    Address: 'Address',
    address: 'Address',
    Date_creation: 'Creation Date',
    Date_creation_error: 'The creation date field is required',
    Age: 'Age',
    Add: 'Add',
    Profil: 'Profile',
    PROFIL: 'PROFILE',
    Nom_Prenons: 'First & Last Name',
    mail: 'Email',
    username: 'Username',
    Phone: 'Phone',
    Compagnie: 'Company',
    Ajouter: 'Add',
    AJOUTER_UN: 'ADD ONE',
    AJOUTER_UNE: 'ADD ONE',
    MODIFICATION_DE_L: 'MODIFY THE',
    MODIFICATION_DE: 'MODIFY THE',
    MODIFICATION_D: 'MODIFY ONE',
    MODIFICATION_DU: 'MODIFY THE',
    UTILISATEUR: 'USER',
    UTILISATEURS: 'Users',
    Modifier: 'Edit',
    Supprimer: 'Delete',
    Action: 'Action',
    Rôle: 'Role',
    Nom: 'Last Name',
    Prénoms: 'First Name',
    Soumettre: 'Submit',
    Annuler: 'Cancel',
    Quit: 'Quit',
    enteteDelete: 'Confirm deletion?',
    confir_upd: 'Confirm update',
    Confirmer: 'Confirm',
    SuppressionUser1: 'The user was successfully deleted.',
    save_error: 'Save failed.',
    save_sucess_user: 'The user was successfully saved.',
    update_user: 'The user was successfully updated.',
    last_name_error: 'The last name field is required',
    first_name_error: 'The first name field is required',
    role_error: 'The role field is required',
    email_error: 'The email field is required',
    username_error: 'The username field is required',
    phoneNumber_error: 'The phone number field is required',
    nom_error: 'The last name field is required',
    SuppressionClient: 'The client was successfully deleted.',
    Company_delete: 'The company was successfully deleted.',
    resource_person: 'Resource Person',
    corporate_save: 'The company was successfully saved.',
    corporate_update: 'The company was successfully updated.',
    COMPAGNIE: 'COMPANY',
    voir: 'View',
    Envoyer_un_mail: 'Send an email',
    Envoyer: 'Send',
    Destinataire: 'Recipient',
    Précédent: 'Previous',
    Suivant: 'Next',
    Annuler_le_traitement_encours: 'Cancel ongoing processing',
    Mettre_encours_de_traitement: 'Start processing',
    message_error: 'The message failed.',
    messsage_envoyer: 'The message was successfully sent.',
    message_update: 'The message was successfully updated.',
    Déconnexion: 'Logout',
    MODIFICATION_DE_MOT_DE_PASSE: 'PASSWORD MODIFICATION',
    Entez_les_infomations_pour_continuer: 'Enter information to continue',
    Mot_de_passe: 'Password',
    Confirmation_mot_de_passe: 'Confirm password',
    veuillez_saisir_le_nouveau_mot_de_passe: 'Please enter the new password',
    MOT_DE_PASSE_CHANGÉ: 'PASSWORD CHANGED',
    ERROR_PASSWORD: 'ERROR',
    Modifier_mon_mot_de_passe: 'Change my password',
    Ouvrez_une_session_pour_commencer_à_utiliser_application: 'Log in to start using the application.',
    Se_souvenir_de_moi: 'Remember me',
    Mot_de_passe_oublié: 'Forgot password?',
    Connexion: 'Login',
    Récupérez_votre_mot_de_passe: 'Recover your password',
    recupération: 'Please enter your email address and we will send you instructions to reset your password.',
    Changer: 'Change',
    password_error: 'The password field is required',
    messages: 'Messages',
    Suppression: 'Deletion failed',
    cities: 'Cities',
    city: 'City',
    CITY: 'CITY',
    roles: 'Roles',
    role: 'Role',
    ROLE: 'ROLE',
    city_update: 'The city was successfully updated.',
    city_error: 'The city field is required',
    city_save: 'The city was successfully saved.',
    SuppressionCities: 'The city was successfully deleted.',
    role_save: 'The role was successfully saved.',
    role_update: 'The role was successfully updated.',
    role_Suppression: 'The role was successfully deleted.',
    age: 'Age',
    gender: 'Gender',
    pattern: 'Pattern',
    modifier: 'Edit',
    supprimer: 'Delete',
    full_name: 'Full Name',
    valider: 'Validate',
    hours: 'Hours',
    quantity: 'Quantity',
    etat: 'State',
    etat_error: 'The state field is required',
    age_error: 'The age field is required',
    gender_error: 'The gender field is required',
    Confirmer_validation: 'Confirm validation',
    birthDate: 'Birthday',
    birthDate_error: 'The birthday field is required',
    nationality: 'Nationality',
    NATIONALITY: 'NATIONALITY',
    nationalities: 'Nationalities',
    nationality_update: 'The nationality was successfully updated.',
    nationality_error: 'The nationality field is required',
    nationality_save: 'The nationality was successfully saved.',
    Suppressionnationality: 'The nationality was successfully deleted.',
    country: 'Country',
    countries: 'Countries',
    COUNTRY: 'COUNTRY',
    country_update: 'The country was successfully updated.',
    country_error: 'The country field is required',
    country_save: 'The country was successfully saved.',
    Suppressioncountry: 'The country was successfully deleted.',
    company: 'Company',
    companies: 'Companies',
    COMPANY: 'COMPANY',
    company_update: 'The company was successfully updated.',
    company_error: 'The company field is required',
    company_save: 'The company was successfully saved.',
    Suppressioncompany: 'The company was successfully deleted.',

    function: 'Function',
    functions: 'Functions',
    FUNCTION: 'FUNCTION',
    function_update: 'The function was successfully updated.',
    function_error: 'The function field is required',
    function_save: 'The function was successfully saved.',
    Suppressionfunction: 'The function was successfully deleted.',

    formation: 'Training',
    formations: 'Types of Training',
    FORMATION: 'TRAINING',
    formation_update: 'The training was successfully updated.',
    formation_error: 'The training field is required',
    formation_save: 'The training was successfully saved.',
    Suppressionformation: 'The training was successfully deleted.',

    insuranceCompanie: 'Insurance Company',
    insuranceCompanies: 'Insurances',
    INSURANCECOMPANIE: 'INSURANCE COMPANY',
    insuranceCompanie_update: 'The insurance company was successfully updated.',
    insuranceCompanie_error: 'The insurance company field is required',
    insuranceCompanie_save: 'The insurance company was successfully saved.',
    SuppressioninsuranceCompanie: 'The insurance company was successfully deleted.',

    equipment: 'PPE',
    equipments: 'PPE',
    EQUIPMENT: 'PPE',
    equipment_update: 'PPE was successfully updated.',
    equipment_error: 'The PPE field is required',
    equipment_save: 'PPE was successfully saved.',
    Suppressionequipments: 'PPE was successfully deleted.',

    equipmentType: 'Tool Type',
    equipmentTypes: 'Tool Types',
    EQUIPEMENTTYPE: 'TOOL TYPE',
    equipmentType_update: 'The tool type was successfully updated.',
    equipmentType_error: 'The tool type field is required',
    equipmentType_save: 'The tool type was successfully saved.',
    SuppressionequipmentTypes: 'The tool type was successfully deleted.',

    //liftingEquipments
    liftingEquipment: 'Lifting Tool',
    liftingEquipments: 'Lifting Tools',
    LIFTINGEQUIPMENT: 'LIFTING TOOLS',
    liftingEquipment_update: 'The lifting tool was successfully updated.',
    liftingEquipment_error: 'The lifting tool field is required',
    liftingEquipment_save: 'The lifting tool was successfully saved.',
    SuppressionliftingEquipment: 'The lifting tool was successfully deleted.',


    vehicle: 'Vehicle',
    vehicles: 'Vehicles',
    VEHICLES: 'VEHICLES',
    vehicle_update: 'The vehicle was successfully updated.',
    vehicle_error: 'The vehicle field is required',
    vehicle_save: 'The vehicle was successfully saved.',
    Suppressionvehicle: 'The vehicle was successfully deleted.',

    task: 'Task',
    tasks: 'Tasks',
    TASKS: 'TASKS',
    task_update: 'The task was successfully updated.',
    task_error: 'The task field is required',
    task_save: 'The task was successfully saved.',
    Suppressiontask: 'The task was successfully deleted.',

    staff: 'Personnel',
    staffs: 'Personnels',
    STAFFS: 'PERSONNEL',
    staff_update: 'The personnel was successfully updated.',
    staff_error: 'The personnel field is required',
    staff_save: 'The personnel was successfully saved.',
    Suppressionstaff: 'The personnel was successfully deleted.',

    security_level: 'Security Level',
    security_level_error: 'The security level field is required',
    norm: 'Standard',
    norm_error: 'The standard field is required',
    otherNorm: 'Other Standard',
    otherNorm_error: 'The other standard field is required',
    number: 'Serial Number',
    number_error: 'The serial number field is required',
    created_at: 'Registration Date',
    updated_at: 'Modification Date',
    description: 'Description',
    Inscrits: 'Registered',
    Localities: 'Localities',
    phone_error: 'The phone field is required',

    staffHasEquipment: 'PPE Assignment',
    staffHasEquipments: 'PPE Assignments',
    STAFFHASEQUIPMENTS: 'PPE ASSIGNMENTS',
    staffHasEquipment_update: 'The PPE assignment was successfully updated.',
    staffHasEquipmentStaff_error: 'The personnel field is required',
    staffHasEquipmentEquipments_error: 'The equipment(s) field is required',
    staffHasEquipment_save: 'The PPE assignment was successfully saved.',
    SuppressionstaffHasEquipment: 'The PPE assignment was successfully deleted.',

    team: 'Team',
    teams: 'Teams',
    TEAM: 'TEAM',
    team_update: 'The team was successfully updated.',
    team_error: 'The team field is required',
    team_save: 'The team was successfully saved.',
    Suppressionteam: 'The team was successfully deleted.',
    Staff_Formations: 'Training Matrix',

    site: 'Site',
    sites: 'Sites',
    SITES: 'SITES',
    site_update: 'The site was successfully updated.',
    site_error: 'The site field is required',
    site_save: 'The site was successfully saved.',
    Suppressionsite: 'The site was successfully deleted.',
    site_longitude: 'The longitude field is required',
    site_latitude: 'The latitude field is required',
    longitude: 'Longitude',
    latitude: 'Latitude',

    mission: 'Mission',
    missions: 'Missions',
    MISSIONS: 'MISSIONS',
    mission_update: 'The mission was successfully updated.',
    misson_error: 'The mission field is required',
    mission_save: 'The mission was successfully saved.',
    Suppressionmission: 'The mission was successfully deleted.',
    poNumber: 'Purchase Order Number',
    woReference: 'Work Order',
    numberOfSite: 'Number of Sites',
    startDate: 'Start Date',
    endDate: 'End Date',
    headOfMissions: 'Mission Leader',

    zone: 'Zone',
    zones: 'Zones',
    ZONES: 'ZONES',
    zone_update: 'The zone was successfully updated.',
    zone_error: 'The zone field is required',
    zone_save: 'The zone was successfully saved.',
    Suppressionzone: 'The zone was successfully deleted.',

    riskProfile_ad: 'Additional Risk Profile',
    riskProfile: 'Risk Profile',
    riskProfiles: 'Risk Profiles',
    RISKPROFILES: 'RISK PROFILES',
    riskProfile_update: 'The risk profile was successfully updated.',
    riskProfile_error: 'The risk profile field is required',
    riskProfile_save: 'The risk profile was successfully saved.',
    SuppressionriskProfile: 'The risk profile was successfully deleted.',
    journey: 'Journey',
    journeys: 'Journeys',
    JOURNEYS: 'JOURNEYS',
    journey_update: 'The journey was successfully updated.',
    journey_error: 'The journey field is required',
    journey_save: 'The journey was successfully saved.',
    Suppressionjourney: 'The journey was successfully deleted.',
    departurePlace: 'Departure Place',
    arrivalPlace: 'Arrival Place',
    departureTime: 'Departure Time',
    departurePlaceLongitude: 'Departure Place Longitude',
    departurePlaceLatitude: 'Departure Place Latitude',
    stopTime: 'Arrival Time',
    arrivalPlaceLongitude: 'Arrival Place Longitude',
    arrivalPlaceLatitude: 'Arrival Place Latitude',
    distance: 'Distance',
    duree: 'Duration',
    numberOfBreak: 'Number of Breaks',
    pause: 'Break',
    purpose: 'Purpose',
    breakStartTimes: 'Break Start Time',
    breakEndTimes: 'Break End Time',
    details: 'Details',
    interventionPlan: 'Intervention Plan',
    interventionPlanContact: 'Intervention Plan Contact',
    restriction: 'Restriction',
    applicator: 'Applicator',
    controller: 'Controller',
    readAndApproved: 'Read and Approved',

    measure: 'Safety Measure',
    measures: 'Safety Measures',
    MEASURES: 'SAFETY MEASURES',
    measure_update: 'The safety measure was successfully updated.',
    measure_error: 'The safety measure field is required',
    measure_save: 'The safety measure was successfully saved.',
    Suppressionmeasure: 'The safety measure was successfully deleted.',
    evacuationPlan: 'Evacuation Plan',
    evacuationPlans: 'Evacuation Plans',
    EVACUATIONPLANS: 'EVACUATION PLANS',
    evacuationPlan_update: 'The evacuation plan was successfully updated.',
    evacuationPlan_error: 'The evacuation plan field is required',
    evacuationPlan_save: 'The evacuation plan was successfully saved.',
    SuppressionevacuationPlan: 'The evacuation plan was successfully deleted.',
    evacuationType: 'Evacuation Type',
    priority1: 'Priority 1',
    priority2: 'Priority 2',
    priority3: 'Priority 3',
    nearestAgency1: 'Nearest Agency 1',
    nearestAgency2: 'Nearest Agency 2',
    nearestAgency3: 'Nearest Agency 3',
    service1: 'Service 1',
    service2: 'Service 2',
    service3: 'Service 3',
    phoneNumber1: 'Phone Number 1',
    phoneNumber2: 'Phone Number 2',
    phoneNumber3: 'Phone Number 3',
    distanceAndDurationFromThePlaceOfAccommodation1: 'Distance & duration from accommodation 1',
    distanceAndDurationFromThePlaceOfAccommodation2: 'Distance & duration from accommodation 2',
    distanceAndDurationFromThePlaceOfAccommodation3: 'Distance & duration from accommodation 3',
    documents: 'Documents',
    categorie: 'Category',
    categories: 'Categories',
    CATEGORIE: 'CATEGORY',
    categorie_update: 'The category was successfully updated.',
    categorie_error: 'The category field is required',
    categorie_save: 'The category was successfully saved.',
    Suppressioncategorie: 'The category was successfully deleted.',
    question: 'Question',
    questions: 'Questions',
    QUESTION: 'QUESTION',
    question_update: 'The question was successfully updated.',
    question_error: 'The question field is required',
    question_save: 'The question was successfully saved.',
    Suppressionquestion: 'The question was successfully deleted.',
    riskZoneAssessment: 'Exclusion Zone Assessment',
    riskZoneAssessments: 'Exclusion Zone Assessments',
    RISKZONEASSESSMENTS: 'EXCLUSION ZONE ASSESSMENTS',
    riskZoneAssessment_update: 'The exclusion zone assessment was successfully updated.',
    riskZoneAssessment_error: 'The exclusion zone assessment field is required',
    riskZoneAssessment_save: 'The exclusion zone assessment was successfully saved.',
    SuppressionriskZoneAssessment: 'The exclusion zone assessment was successfully deleted.',
    riskZones: 'Exclusion Zones',
    jobRiskAssessment_step: 'Risk Assessment',
    jobRiskAssessment: 'Job Risk Assessment',
    jobRiskAssessments: 'Job Risk Assessments',
    JOBRISKASSESSMENTS: 'JOB RISK ASSESSMENTS',
    jobRiskAssessment_update: 'The job risk assessment was successfully updated.',
    jobRiskAssessment_error: 'The job risk assessment field is required',
    jobRiskAssessment_save: 'The job risk assessment was successfully saved.',
    SuppressionjobRiskAssessment: 'The job risk assessment was successfully deleted.',
    initialRisk: 'Initial Risk',
    initialRiskSeverity: 'Initial Risk Severity',
    initialRiskProbability: 'Initial Risk Probability',
    comapasatoryMeasures: 'Compensatory Measures',
    residualRisk: 'Residual Risk',
    residualRiskSeverity: 'Residual Risk Severity',
    residualRiskProbability: 'Residual Risk Probability',
    nokiaValidation: 'Nokia Validation',
    nokiaComment: 'Nokia Comment',
    riskDetails: 'Risk Details',
    colorVrai: 'True Choice Color',
    colorFaux: 'False Choice Color',
    colorNa: 'No Choice Color',
    colorVrai_error: 'The true choice color field is required',
    colorFaux_error: 'The false choice color field is required',
    colorNa_error: 'The no choice color field is required',
    planification: 'Missions',
    planification_cloturer: 'Closed Missions',
    planification_NonCloturer: 'Ongoing Missions',
    mission_ref: 'Reference',
    preuve: 'Evidence(s)',
    activitie: 'Activity',
    activities: 'Activities',
    ACTIVITIE: 'ACTIVITY',
    activitie_update: 'The activity was successfully updated.',
    activitie_error: 'The activity field is required',
    activitie_save: 'The activity was successfully saved.',
    Suppressionactivitie: 'The question was successfully deleted.',
    risk: 'Risk',
    risks: 'Risks',
    RISK: 'RISK',
    risk_update: 'The risk was successfully updated.',
    risk_error: 'The risk field is required',
    risk_save: 'The risk was successfully saved.',
    Suppressionrisk: 'The risk was successfully deleted.',
    activity: 'Activity',
    riskAssessments: 'Risk Assessments',
    inactivation_teams: 'Teams Under Verification',
    activated_teams: 'Validated Teams',
    rejected_teams: 'Rejected Teams',
    inactivation_vehicles: 'Vehicles Under Verification',
    activated_vehicles: 'Validated Vehicles',
    rejected_vehicles: 'Rejected Vehicles',
    inactivation_staffs: 'Staff Under Verification',
    activated_staffs: 'Validated Staff',
    rejected_staffs: 'Rejected Staff',
    unclosedMissions: 'Ongoing Missions',
    enclosedMissions: 'Closed Missions',
    inactivation_liftingEquipments: 'Lifting Tools Under Verification',
    activated_liftingEquipments: 'Validated Lifting Tools',
    rejected_liftingEquipments: 'Rejected Lifting Tools',
    inactivation_equipments: 'PPE Under Verification',
    activated_equipments: 'Validated PPE',
    rejected_equipments: 'Rejected PPE',
    image_error: 'The image field is required',
    name_file1_3: 'The file is required',
    project: 'Project',
    projects: 'Projects',
    PROJECTS: 'PROJECTS',
    project_update: 'The project was successfully updated.',
    project_error: 'The project field is required',
    project_save: 'The project was successfully saved.',
    Suppressionproject: 'The project was successfully deleted.',
    pessNokia: 'PESS NOKIA',
    rom: 'ROM',
    qhsNokia: 'QHS NOKIA',
    leadProject: 'PROJECT LEADER',
    name: 'Full Name',
    pessNokia_error: 'The PESS Nokia field is required',
    rom_error: 'The ROM field is required',
    qhsNokia_error: 'The QHS Nokia field is required',
    name_error: 'The full name field is required',
    leadProject_error: 'The lead project field is required',
    description_error: 'The description field is required',
    raison: 'Reason',
    raison_error: 'The project field is required',
    scheduled_date: 'Scheduled Date',
    scheduled_date_error: 'The project field is required',
    meetings: 'Meetings',
    is_satisfied: 'Satisfaction',
    Suppressionmeeting: 'The meeting was successfully deleted.',
    reserve_comment: 'Comment',
    reserve_type: 'Reserve Type',
    reserve_comment_error: 'The comment field is required',
    reserve_type_error: 'The reserve type field is required',
    purchase: 'Purchase Order',
    purchase_error: 'The purchase order field is required',
    purchase_save: 'The purchase order was successfully saved.',
    purchase_update: 'The purchase order was successfully updated.',
    Suppressionpurchases: 'The purchase order was successfully deleted.',
    orderOfWorks: 'Work Order',
    orderOfWork_error: 'The work order field is required.',
    orderOfWork_save: 'The work order was successfully saved.',
    orderOfWork_update: 'The work order was successfully updated.',
    SuppressionorderOfWork: 'The work order was successfully deleted.',
    validations: 'Validations',
    libraries: 'Risk Libraries',
    library: 'Risk Library',
    LIBRARY: 'RISK LIBRARY',
    library_error: 'The risk library is required.',
    library_save: 'The risk  library was successfully saved.',
    library_update: 'The risk library was successfully updated.',
    Suppressionlibrary: 'The risk library was successfully deleted.',
    teamLeader: 'Subcontractor Team Leader',
    leader: 'Leader',
    securityQuestion: 'Enumerate a security risk',
    securityQuestions: 'Security Questionnaire',
    SECURITYQUESTION: 'SECURITY QUESTIONNAIRE',
    securityQuestions_error: 'Enumerating the security risk is required.',
    securityQuestions_save: 'The security risk was successfully saved.',
    securityQuestions_update: 'The security risk was successfully updated.',
    SuppressionsecurityQuestions: 'The security risk was successfully deleted.',
    siteGroup: 'Type of security Evaluation',
    securityEvaluation: 'Security Evaluation',
    securityEvaluations: 'Security Evaluations',
    SECURITYEVALUATION: 'SECURITY EVALUATION',
    securityEvaluations_error: 'The security evaluation is required.',
    securityEvaluations_save: 'The security evaluation was successfully saved.',
    securityEvaluations_update: 'The security evaluation was successfully updated.',
    SuppressionsecurityEvaluations: 'The safety evaluation was successfully deleted.',
    eva_instable: 'INSTABILITY ASSESSMENT',
    eva_vunerable: 'VULNERABILITY ASSESSMENT',
    eva_consequance: 'CONSEQUENCE ASSESSMENT',
    eva_probabilite: 'PROBABILITY ASSESSMENT',
    timing: 'Timing',
    feasibility: 'Feasibility',
    achievableInMoreFavorablePeriod: 'Achievable in a more favorable period',
    notFeasibleOverLongPeriod: 'Not feasible over a long period',
    initializeCmt: 'Initialize a CMT',
    comment: 'Recommendation',
    score: 'Score',
    final_result: 'Final Result',
    mission_error: 'The mission field is required',
    timing_error: 'The timing field is required',
    siteGroup_error: 'The type of evaluation field is required',
    comment_error: 'The recommendation field is required',
    securityEvalution_error: 'The security evaluation is required.',
    securityEvalution_save: 'The security evaluation was successfully saved.',
    securityEvalution_update: 'The security evaluation was successfully updated.',
    SuppressionsecurityEvalution: 'The security evaluation was successfully deleted.',
    reporting: 'Reporting',
    validate_trajet: 'Journey Validation',
    mission_status: 'Mission Status',
    Date_début:'Date start',
    Date_fin:'Date end'

  },

  //FRANCAIS 

  fr: {
    Dashboard: 'Tableau de bord',
    Utilisateurs: 'Utilisateurs',
    Compagnies: 'Entreprises',
    Address:'Adresse',
    address:'Adresse',
    Date_creation:'Date de création',
    Date_creation_error:'Le champ date de création est obligatoire',
    Age :'Âge',
    Add: 'Ajouter',
    Profil: 'Profil',
    PROFIL: 'PROFIL',
    Nom_Prenons: 'Nom & Prénoms',
    mail: 'E-mail',
    username:'Nom d\'utilisateur',
    Phone: 'Téléphone',
    Compagnie: 'Entreprise',
    Ajouter: 'Ajouter',
    AJOUTER_UN: 'AJOUTER UN',
    AJOUTER_UNE: 'AJOUTER UNE',
    MODIFICATION_DE_L: 'MODIFIER L\'',
    MODIFICATION_DE: 'MODIFIER LA',
    MODIFICATION_D: 'MODIFIER D\'UN',
    MODIFICATION_DU: 'MODIFIER LE',
    UTILISATEUR: 'UTILISATEUR',
    UTILISATEURS: 'Utilisateurs',
    Modifier: 'Modifier',
    Supprimer: 'Supprimer',
    Action: 'Action',
    Rôle: 'Rôle',
    Nom: 'Nom',
    Prénoms: 'Prénoms',
    Soumettre: 'Soumettre',
    Annuler: 'Annuler',
    Quit:'Quitter',
    enteteDelete: 'Confirmer la suppression?',
    confir_upd:'Confirmer la mise à jour',
    Confirmer :'confirmer',
    SuppressionUser1:'L\'utilutilsateur est supprimé avec succès.',
    save_error:'Enrégistrement échoué.',
    save_sucess_user:'L\'utilisateur est enrégistré avec succès.',
    update_user:'L\'utilsateur est modifié avec succès.',
    last_name_error:  'Le champ nom est obligatoire',
    first_name_error: 'Le champ prénoms est obligatoire',
    role_error:  'Le champ role est obligatoire',
    email_error: 'Le champ e-mail est obligatoire',
    username_error: 'Le champ nom d\'utilisateur est obligatoire',
    phoneNumber_error:'Le champ téléphone est obligatoire',
    nom_error: 'Le champ nom est obligatoire',
    SuppressionClient:'La client est supprimé avec succès.',
  
 
    Company_delete :'L\'entreprise est supprimée avec succès.',
    resource_person:'Personne ressource',
    corporate_save: 'L\'entreprise est enrégistrée avec succès.',
    corporate_update : 'L\'entreprise est modifiée avec succès.',
    COMPAGNIE:'ENTREPRISE',
 
    voir:'Voir',
    Envoyer_un_mail:'Envoyer un mail',
    Envoyer:'Envoyer',
    Destinataire:'Destinataire',
  
    Précédent:'Précédent',
    Suivant:'Suivant',
    Annuler_le_traitement_encours:'Annuler le traitement encours',
    Mettre_encours_de_traitement:'Mettre encours de traitement',
    message_error:'Le message à échouer.',
    messsage_envoyer:'Le message est envoyé avec succès.',
    message_update:'Le message est modifié avec succès.',
    Déconnexion:'Déconnexion',
    MODIFICATION_DE_MOT_DE_PASSE:'MODIFICATION DE MOT DE PASSE',
    Entez_les_infomations_pour_continuer:'Entez les infomations pour continuer',
    Mot_de_passe:'Mot de passe',
    Confirmation_mot_de_passe:'Confirmation mot de passe',
    veuillez_saisir_le_nouveau_mot_de_passe:'veuillez saisir le nouveau mot de passe',
    MOT_DE_PASSE_CHANGÉ:'MOT DE PASSE CHANGÉ',
    ERROR_PASSWORD:'ERREUR',
    Modifier_mon_mot_de_passe:'Modifier mon mot de passe',
    Ouvrez_une_session_pour_commencer_à_utiliser_application:'Ouvrez une session pour commencer à utiliser l\'application.',
    Se_souvenir_de_moi:'Se souvenir de moi',
    Mot_de_passe_oublié:'Mot de passe oublié?',
    Connexion:'Connexion',
    Récupérez_votre_mot_de_passe:'Récupérez votre mot de passe',
    recupération:'Veuillez saisir votre adresse e-mail et nous vous enverrons des instructions pour réinitialiser votre mot de passe.',
    Changer:'Changer',
    password_error:'Le champ mot de passe est obligatoire',
    messages:'Messages',
    Suppression:'Suppression échouée',

    cities:'Villes',
    city:'Ville',
    CITY:'VILLE',
 
    roles:'Rôles',
    role:'Role',
    ROLE:'ROLE',

    city_update:'La ville est modifiée avec succès.',
    city_error:'Le champ ville  est obligatoire',
    city_save:'La ville est enrégistrée avec succès.',
    SuppressionCities:'La ville est supprimée avec succès.',


    role_save:'Le rôle est enrégistré avec succès.',
    role_update:'Le rôle est modifié avec succès.',
    role_Suppression:'Le rôle est supprimé avec succès.',

    age:'Âge',
    gender:'Sexe',
    pattern:'Motif',
 
    modifier:'Modifier',
    supprimer:'Supprimer',
    full_name:'Nom complet',
    valider:'valider',
    hours:'heures',

    quantity:'Quantité',


    etat :'État',
 
    etat_error:'Le champ état est obligatoire',
    age_error:'Le champ âge est obligatoire',
    gender_error:'Le champ sexe est obligatoire',

  
    Confirmer_validation:'Confirmer la validation',


    birthDate:'Date d\'anniversaire',
    birthDate_error:'Le champ date d\'anniversaire est obligatoire',

    nationality:'Nationalité',
    NATIONALITY:'NATIONALITÉ',
    nationalities:'Nationalités',
    nationality_update:'La nationalité est modifiée avec succès.',
    nationality_error:'La champ nationalité  est obligatoire',
    nationality_save:'La nationalité est enrégistrée avec succès.',
    Suppressionnationality:'La nationalité est supprimée avec succès.',

    country:'Pays',
    countries:'Pays',
    COUNTRY:'PAYS',
    country_update:'Le pays est modifié avec succès.',
    country_error:'Le champ pays  est obligatoire',
    country_save:'Le pays est enrégistré avec succès.',
    Suppressioncountry:'Le pays est supprimé avec succès.',

    company:'Compagnie',
    companies:'Compagnies',
    COMPANY:'COMPAGNIE',
    company_update:'La compagnie est modifiée avec succès.',
    company_error:'Le champ compagnie est obligatoire',
    company_save:'La compagnie est enrégistrée avec succès.',
    Suppressioncompany:'La compagnie est supprimée avec succès.',


    function:'Fonction',
    functions:'Fonctions',
    FUNCTION:'FONCTION',
    function_update:'La fonction est modifiée avec succès.',
    function_error:'Le champ fonction est obligatoire',
    function_save:'La fonction est enrégistrée avec succès.',
    Suppressionfunction:'La fonction est supprimée avec succès.',

    formation:'Formation',
    formations:'Type de Formations',
    FORMATION:'FORMATION',
    formation_update:'La formation est modifiée avec succès.',
    formation_error:'Le champ formation est obligatoire',
    formation_save:'La formation est enrégistrée avec succès.',
    Suppressionformation:'La formation est supprimée avec succès.',

    ///insuranceCompanies,

    insuranceCompanie:'Compagnie d\'assurance',
    insuranceCompanies:'Assurances',
    INSURANCECOMPANIE:'COMPAGNIE D\'ASSURANCE',
    insuranceCompanie_update:'La compagnie d\'assurance est modifiée avec succès.',
    insuranceCompanie_error:'Le champ compagnie d\'assurance est obligatoire',
    insuranceCompanie_save:'La compagnie d\'assurance est enrégistrée avec succès.',
    SuppressioninsuranceCompanie:'La compagnie d\'assurance est supprimée avec succès.',

    //equipments
    equipment:'EPI',
    equipments:'EPI',
    EQUIPMENT:'EPI',
    equipment_update:'EPI est modifié avec succès.',
    equipment_error:'Le champ EPI est obligatoire',
    equipment_save:'EPI est enrégistré avec succès.',
    Suppressionequipments:'EPI est supprimé avec succès.',

    equipmentType:'Type d\'outil',
    equipmentTypes:'Types d\'outils',
    EQUIPEMENTTYPE:'TYPE D\'OUTIL',
    equipmentType_update:'Le type d\'outil est modifié avec succès.',
    equipmentType_error:'Le champ type d\'outil est obligatoire',
    equipmentType_save:'Type d\'outil est enrégistré avec succès.',
    SuppressionequipmentTypes:'Type d\'outil est supprimé avec succès.',
    //liftingEquipments

    liftingEquipment:'Outil de levage',
    liftingEquipments:'Outils de levage',
    LIFTINGEQUIPMENT:'OUTILS DE LEVAGE',
    liftingEquipment_update:'L\'outil de levage est modifié avec succès.',
    liftingEquipment_error:'Le champ outil de levage est obligatoire',
    liftingEquipment_save:'Outil de levage est enrégistré avec succès.',
    SuppressionliftingEquipment:'L\'outil de levage est supprimé avec succès.',

    vehicle:'Véhicule',
    vehicles:'Véhicules',
    VEHICLES:'VÉHICULES',
    vehicle_update:'Véhicule est modifié avec succès.',
    vehicle_error:'Le champ véhicule est obligatoire',
    vehicle_save:'le véhicule est enrégistré avec succès.',
    Suppressionvehicle:'Le véhicule est supprimé avec succès.',

    task:'Tâche',
    tasks:'Tâches',
    TASKS:'TÂCHES',
    task_update:'Tâche est modifiée avec succès.',
    task_error:'Le champ tâche est obligatoire',
    task_save:'la tâche est enrégistrée avec succès.',
    Suppressiontask:'La tâche est supprimée avec succès.',

    staff:'Personnel',
    staffs:'Personnels',
    STAFFS:'PERSONNEL',
    staff_update:'Personnel est modifié avec succès.',
    staff_error:'Le champ personnel est obligatoire',
    staff_save:'le personnel est enrégistré avec succès.',
    Suppressionstaff:'Le personnel est supprimé avec succès.',

    security_level:'Niveau de sécurité',
    security_level_error:'Le champ niveau de sécurité est obligatoire',
    norm:'Norme',
    norm_error:'Le champ norme est obligatoire',
    otherNorm:'Autre norme',
    otherNorm_error:'Le champ autre norme est obligatoire',
    number:'Numéro de série',
    number_error:'Le champ identifiant est obligatoire',
    created_at:'Date d\'enrégistrement',
    updated_at:'Date de modification',
    description:'Description',


    Inscrits:'Inscrits',
    Localities:'Localités',

    phone_error:'Le champ téléphone est obligatoire',

    staffHasEquipment:'Affectation d\'EPI',
    staffHasEquipments:'Affectations d\'EPI',
    STAFFHASEQUIPMENTS:'AFFECTATIONS D\'EPI',
    staffHasEquipment_update:'L\'affectation d\'EPI est modifiée avec succès.',
    staffHasEquipmentStaff_error:'Le champ personnel est obligatoire',
    staffHasEquipmentEquipments_error:'Le champ équipemt(s) est obligatoire',
    staffHasEquipment_save:'L\'affectation d\'EPI est enrégistrée avec succès.',
    SuppressionstaffHasEquipment:'L\'affectation d\'EPI est supprimée avec succès.',

    team:'Équipe',
    teams:'Équipes',
    TEAM:'Équipe',
    team_update:'L\'équipe est modifié avec succès.',
    team_error:'Le champ équipe est obligatoire',
    team_save:'l\'équipe est enrégistré avec succès.',
    Suppressionteam:'L\'équipe est supprimé avec succès.',

    Staff_Formations:'Matrice de Formations',
  
    site:'Site',
    sites:'Sites',
    SITES:'SITES',
    site_update:'Site est modifié avec succès.',
    site_error:'Le champ site est obligatoire',
    site_save:'le site est enrégistré avec succès.',
    Suppressionsite:'Le site est supprimé avec succès.',
    site_longitude:'Le champ longitude est obligatoire',
    site_latitude:'Le champ latitude est obligatoire',
    longitude:'Longitude',
    latitude:'Latitude',

    mission:'Mission',
    missions:'Missions',
    MISSIONS:'MISSIONS',
    mission_update:'Mission est modifiée avec succès.',
    misson_error:'Le champ mission est obligatoire',
    mission_save:'la mission est enrégistrée avec succès.',
    Suppressionmission:'La mission est supprimée avec succès.',

    poNumber:'Numéro de bon de commande',
    woReference:'Ordre de travail',
    numberOfSite:'Nombre de site',
    startDate:'Date de début',
    endDate:'Date de fin',
    headOfMissions:'Chef de mission',

    zone:'Zone',
    zones:'Zones',
    ZONES:'ZONES',
    zone_update:'Zone est modifiée avec succès.',
    zone_error:'Le champ zone est obligatoire',
    zone_save:'la zone est enrégistrée avec succès.',
    Suppressionzone:'La zone est supprimée avec succès.',

    riskProfile_ad:'Profil de risque additionnel',
    riskProfile:'Profil de risque',
    riskProfiles:'Profils de risque    ',
    RISKPROFILES:'PROFIL DE RISQUE',
    riskProfile_update:'Profil de risque est modifié avec succès.',
    riskProfile_error:'Le champ profil de risque est obligatoire',
    riskProfile_save:'le profil de risque est enrégistré avec succès.',
    SuppressionriskProfile:'Le profil de risque est supprimé avec succès.',

    journey:'Trajet',
    journeys:'Trajets',
    JOURNEYS:'TRAJETS',
    journey_update:'Trajet est modifié avec succès.',
    journey_error:'Le champ trajet est obligatoire',
    journey_save:'le trajet est enrégistré avec succès.',
    Suppressionjourney:'Le trajet est supprimé avec succès.',

    departurePlace : 'Lieu de départ',
    arrivalPlace : 'Lieu d\'arrivée',
    departureTime:  'Heure de départ',
    departurePlaceLongitude:'Longitude du lieu de départ',
    departurePlaceLatitude:'Latitude du lieu de départ',
    stopTime: 'Heure d\'arrivée',
    arrivalPlaceLongitude:'Longitude du lieu d\'arrivée',
    arrivalPlaceLatitude:'Latitude du lieu d\'arrivée',
    distance : 'Distance',
    duree : 'Durée',
    numberOfBreak : 'Nombre de pause',
    pause : 'Pause',
    purpose : 'Libelle',
    breakStartTimes : 'Début de pause',
    breakEndTimes : 'Fin de pause',
    details : 'Détails',
    interventionPlan :'Plan d\'intervention',
    interventionPlanContact :'Plan d\'intervention Contact',
    restriction : 'Restriction',
    applicator :'Applicateur',
    controller : 'Contrôleur',

    readAndApproved:'Lu et Approuvé',
    //evacuationPlans


    measure : 'Mesure de sécurité',
    measures : 'Mesures de sécurité',
    MEASURES:'MESURE DE SÉCURITÉ',
    measure_update:'La mesure de sécurité est modifiée avec succès.',
    measure_error:'Le champ mesure de sécurité est obligatoire',
    measure_save:'la mesure de sécurité est enrégistrée avec succès.',
    Suppressionmeasure:'La mesure de sécurité est supprimée avec succès.',

    evacuationPlan:'Plan d\'évacuation',
    evacuationPlans:'Plans d\'évacuations',
    EVACUATIONPLANS:'PLAN D\'ÉVACUATION',
    evacuationPlan_update:'Plan d\'évacuation est modifié avec succès.',
    evacuationPlan_error:'Le champ plan d\'évacuation est obligatoire',
    evacuationPlan_save:'le plan d\'évacuation est enrégistré avec succès.',
    SuppressionevacuationPlan:'Le plan d\'évacuation est supprimé avec succès.',
    evacuationType:'Type d\'évacuation',
    priority1:'Priorité 1',
    priority2:'Priorité 2',
    priority3:'Priorité 3',
    nearestAgency1:'Agence la plus proche 1',
    nearestAgency2:'Agence la plus proche 2',
    nearestAgency3:'Agence la plus proche 3',
    service1:'Service 1',
    service2:'Service 2',
    service3:'Service 3',
    phoneNumber1:'Numéro de téléphone 1',
    phoneNumber2:'Numéro de téléphone 2',
    phoneNumber3:'Numéro de téléphone 3',
    distanceAndDurationFromThePlaceOfAccommodation1:'Distance & durée d\'hébergemt.1',
    distanceAndDurationFromThePlaceOfAccommodation2:'Distance&durée d\'hébergemt.2',
    distanceAndDurationFromThePlaceOfAccommodation3:'Distance&durée d\'hébergemt.3',
    documents:'Documents',

    categorie : 'Catégorie',
    categories : 'Catégories',
    CATEGORIE:'CATÉGORIE',
    categorie_update:'La catégorie est modifiée avec succès.',
    categorie_error:'Le champ catégorie est obligatoire',
    categorie_save:'la catégorie est enrégistrée avec succès.',
    Suppressioncategorie:'La catégorie est supprimée avec succès.',

    question : 'Question',
    questions : 'Questions',
    QUESTION:'QUESTION',
    question_update:'La question est modifiée avec succès.',
    question_error:'Le champ question est obligatoire',
    question_save:'la question est enrégistrée avec succès.',
    Suppressionquestion:'La question est supprimée avec succès.',

    riskZoneAssessment : 'Évaluation de la zone d\'exclusion',
    riskZoneAssessments : 'Évaluations de la zone d\'exclusion',
    RISKZONEASSESSMENTS:'ÉVALUATIONS DE LA ZONE D\'EXCLUSION',
    riskZoneAssessment_update:'L\'évaluation de la zone d\'exclusion est modifiée avec succès.',
    riskZoneAssessment_error:'Le champ évaluation de la zone d\'exclusion est obligatoire',
    riskZoneAssessment_save:'L\'évaluation de la zone d\'exclusion est enrégistrée avec succès.',
    SuppressionriskZoneAssessment:'L\'évaluation de la zone d\'exclusion est supprimée avec succès.',

    riskZones:'Zones d\'exclusion',
    jobRiskAssessment_step:'Évaluation de risque',
    jobRiskAssessment : 'Évaluation de risque d\'emploi',
    jobRiskAssessments : 'Évaluations des risques d\'emploi',
    JOBRISKASSESSMENTS:'ÉVALUATIONS DES RISQUES D\'EMPLOI',
    jobRiskAssessment_update:'L\'évaluation de risque d\'emploi est modifiée avec succès.',
    jobRiskAssessment_error:'Le champ évaluation de risque d\'emploi est obligatoire',
    jobRiskAssessment_save:'L\'évaluation de risque d\'emploi est enrégistrée avec succès.',
    SuppressionjobRiskAssessment:'L\'évaluation de risque d\'emploi est supprimée avec succès.',

    initialRisk:'Risque initial',
    initialRiskSeverity:'Gravité initiale du risque',
    initialRiskProbability:'Probabilité de risque initiale',
    comapasatoryMeasures:'Mesures compensatoires',
    residualRisk:'Risque résiduel',
    residualRiskSeverity:'Gravité du risque résiduel',
    residualRiskProbability:'Probabilité de risque résiduel',
    nokiaValidation:'Validation Nokia',
    nokiaComment:'Commentaire Nokia',
    riskDetails:'Détails du risque',
    colorVrai: 'Couleur du choix vrai',
    colorFaux: 'Couleur du choix faux',
    colorNa: 'Couleur du choix néant',
    colorVrai_error:'Le champ couleur du choix vrai est obligatoire',
    colorFaux_error:'Le champ couleur du choix faux est obligatoire',
    colorNa_error:'Le champ couleur du choix faux est obligatoire',

    planification:'Missions',
    planification_cloturer:'Missions clôturées',
    planification_NonCloturer:'Missions en cours',

    mission_ref:'Référence',
    //activities
    preuve :'Preuve(e)s',
    activitie : 'Activité',
    activities : 'Activités',
    ACTIVITIE:'ACTIVITÉ',
    activitie_update:'l\'activité est modifiée avec succès.',
    activitie_error:'Le champ activité est obligatoire',
    activitie_save:'l\'activité est enrégistrée avec succès.',
    Suppressionactivitie:'La question est supprimée avec succès.',

    risk:'Risque',
    risks:'Risques',
    RISK:'RISQUE',
    risk_update:'Risque est modifié avec succès.',
    risk_error:'Le champ risque est obligatoire',
    risk_save:'le risque est enrégistré avec succès.',
    Suppressionrisk:'Le risque est supprimé avec succès.',

  
    activity:'Activité',
    riskAssessments:'Évaluations des risques',

    inactivation_teams:'Équipes en vérifications',
    activated_teams:'Équipes validées',
    rejected_teams:'Équipes rejetées',

    inactivation_vehicles :'Véhicules en vérifications',
    activated_vehicles :'Véhicules validés',
    rejected_vehicles :'Véhicules rejetés',

    inactivation_staffs:'Personnels en vérifications',
    activated_staffs:'Personnels validés',
    rejected_staffs:'Personnels rejetés',

    unclosedMissions:'Missions en cours',
    enclosedMissions:'Missions cloturées',

    inactivation_liftingEquipments:'Outils de levage en vérifications',
    activated_liftingEquipments:'Outils de levage validés',
    rejected_liftingEquipments:'Outils de levage rejetés',

    inactivation_equipments:'EPI en vérifications',
    activated_equipments:'EPI validés',
    rejected_equipments :'EPI rejetés',
    image_error:'Le champ image est obligatoire',
    name_file1_3:'Le fichier  est obligatoire',

    project:'Projet',
    projects:'Projets',
    PROJECTS:'PROJETS',
    project_update:'Projet est modifié avec succès.',
    project_error:'Le champ projet est obligatoire',
    project_save:'le projet est enrégistré avec succès.',
    Suppressionproject:'Le projet est supprimé avec succès.',

    pessNokia: 'PESS NOKIA',
    rom:'ROM',
    qhsNokia:'QHS NOKIA',
    leadProject:'CHEF PROJET',
    name:'Nom complet',

    pessNokia_error:'Le champ pessnokia est obligatoire',
    rom_error:'Le champ rom est obligatoire',
    qhsNokia_error:'Le champ qfh nokia est obligatoire',
    name_error:'Le champ nom complet est obligatoire',
    leadProject_error:'Le champ lead projet est obligatoire',
    description_error:'Le champ description est obligatoire',

    raison:'Raison',
    raison_error:'Le champ projet est obligatoire',

    scheduled_date:'Date programmée',
    scheduled_date_error:'Le champ projet est obligatoire',

    meetings:'Réunions',
    is_satisfied :'Satisfaction',
    Suppressionmeeting:'La réunion est supprimée avec succès.',

    reserve_comment:'Commentaire',
    reserve_type:'Type de reserve',

    reserve_comment_error:'Le champ commentaire est obligatoire',
    reserve_type_error:'Le champ type de reserve est obligatoire',

    purchase:'Bon de commande',
    purchase_error:'Le champ bon de commande est obligatoire',
    purchase_save:'le bon de commande est enrégistré avec succès.',
    purchase_update:'le bon de commande est modifié avec succès.',
    Suppressionpurchases:'le bon de commande est supprimé avec succès.',
 
    orderOfWorks:'Ordre de travail',
    orderOfWork_error:'Le champs ordre de travail est obligatoire.',
    orderOfWork_save:'L\'ordre de travail est enrégistré avec succès.',
    orderOfWork_update:'L\'ordre de travail est modifié avec succès.',
    SuppressionorderOfWork:'L\'ordre de travail est supprimé avec succès.',
    validations:'Validations',

    libraries:'Bibliothèques',
    library:'Bibliothèque',
    LIBRARY:'BIBLIOTHÈQUE',
    library_error:'La bibliothèque est obligatoire.',
    library_save:'La bibliothèque est enrégistrée avec succès.',
    library_update:'la bibliothèque est modifiée avec succès.',
    Suppressionlibrary:'la bibliothèque est supprimée avec succès.',

    teamLeader:'Team Leader Sous-traitant',
    leader:'Chef',

    securityQuestion:'Enumérer un risque sécuritaire',
    securityQuestions:'Questionnaire sécurité',
    SECURITYQUESTION:'QUESTIONNAIRE SÉCURITE',
    securityQuestions_error:'L\'énumération du risque sécuritaire est obligatoire.',
    securityQuestions_save:'Le risque sécuritaire est enrégistrée avec succès.',
    securityQuestions_update:'la risque sécuritaire est modifiée avec succès.',
    SuppressionsecurityQuestions:'le risque sécuritaire est supprimée avec succès.',

    siteGroup:'Type d\'évaluation sécuritaire',
    securityEvaluation:'Évaluation sécuritaire',
    securityEvaluations:'Évaluations sécuritaires',
    SECURITYEVALUATION:'ÉVALUATION SÉCURITAIRE',
    securityEvaluations_error:'L\'évaluation sécuritaire est obligatoire.',
    securityEvaluations_save:'L\'évaluation sécuritaire est enrégistrée avec succès.',
    securityEvaluations_update:'L\'évaluation sécuritaire est modifiée avec succès.',
    SuppressionsecurityEvaluations:'L\'évaluation sécuritaire est supprimée avec succès.',

    eva_instable:'ÉVALUATION DE L\'INSTABILITÉ',
    eva_vunerable:'ÉVALUATION DE LA VULNÉRABILITÉ',
    eva_consequance:'ÉVALUATION DES CONSÉQUENCES',
    eva_probabilite:'ÉVALUATION DE LA PROBABILITÉ',
  
    timing:'Timing',
    feasibility:'Faisabilité',
    achievableInMoreFavorablePeriod:'Réalisable dans une periode plus propice',
    notFeasibleOverLongPeriod:'Non réalisable sur une longue periode',
    initializeCmt:'Initialiser un CMT',
    comment:'Recommandation',
    score:'Score',
    final_result:'Résultat final',

    mission_error:'Le champ mission est obligatoire',
    timing_error:'Le champ timing est obligatoire',
    siteGroup_error:'Le champ type d\'évaluation est obligatoire',
    comment_error:'Le champ recommandation est obligatoire',

    securityEvalution_error:'l\'évaluation sécuritaire est obligatoire.',
    securityEvalution_save:'l\'évaluation sécuritaire est enrégistrée avec succès.',
    securityEvalution_update:'l\'évaluation sécuritaire est modifiée avec succès.',
    SuppressionsecurityEvalution:'l\'évaluation sécuritaire est supprimée avec succès.',

    reporting:'Reporting',
    validate_trajet:'Validation Trajet',
    mission_status:'Statut de la mission',

    Date_début:'Date début',
    Date_fin:'Date fin'

  }
}